<template>
  <div class="wap-goods classify">
    <headerBar :title="title" @back="back"></headerBar>
    <div class="filter-option flex-center-between">
      <div
        class="option"
        :class="tabIndex == 1 ? 'active' : ''"
        @click="changeOption(1)"
      >
        {{ $t('zong-he') }}
      </div>
      <div
        class="option flex-center"
        :class="tabIndex == 2 ? 'active' : ''"
        @click="changeOption(2)"
      >
        {{ $t('xiao-shou-liang') }}
        <div class="flex-column-center icon">
          <i
            class="el-icon-caret-top"
            :class="tabIndex == 2 && sortType == 'asc' ? 'active' : ''"
          ></i>
          <i
            class="el-icon-caret-bottom"
            :class="tabIndex == 2 && sortType == 'desc' ? 'active' : ''"
          ></i>
        </div>
      </div>
      <div
        class="option flex-center"
        :class="tabIndex == 3 ? 'active' : ''"
        @click="changeOption(3)"
      >
        {{ $t('jia-ge') }}
        <div class="flex-column-center icon">
          <i
            class="el-icon-caret-top"
            :class="tabIndex == 3 && sortType == 'asc' ? 'active' : ''"
          ></i>
          <i
            class="el-icon-caret-bottom"
            :class="tabIndex == 3 && sortType == 'desc' ? 'active' : ''"
          ></i>
        </div>
      </div>
      <div
        class="option flex-center"
        :class="tabIndex == 4 ? 'active' : ''"
        @click="changeOption(4)"
      >
        {{ $t('shang-xin') }}
        <div class="flex-column-center icon">
          <i
            class="el-icon-caret-top"
            :class="tabIndex == 4 && sortType == 'asc' ? 'active' : ''"
          ></i>
          <i
            class="el-icon-caret-bottom"
            :class="tabIndex == 4 && sortType == 'desc' ? 'active' : ''"
          ></i>
        </div>
      </div>
    </div>

    <div class="goods">
      <List
        v-if="goodsList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.noMore')"
        @load="onLoad"
      >
        <goodsList :list="goodsList"></goodsList>
      </List>

      <div class="empty-box flex-column-center" v-if="goodsList.length == 0">
        <img :src="emptyUrl" alt="" />
        <span>{{ $t('zan-wu-shu-ju') }}</span>
      </div>
    </div>

    <categoryBar
      :showPopup="showPopup"
      @submit="chooseCategory"
      @cancel="cancel"
    ></categoryBar>
  </div>
</template>
<script>
import { Button, List, Popup, Icon } from 'vant'
import { shopProductList } from '@/api/shop'
import goodsList from '@/components/goodsList/wapUserList'
import categoryBar from '@/components/category'
import headerBar from '@/components/header'
export default {
  name: 'about',
  components: {
    goodsList,
    Button,
    Popup,
    Icon,
    List,
    categoryBar,
    headerBar
  },
  data() {
    return {
      activeIndex: 0,
      categoryUrl: require('@/assets/imgs/icon-category.png'),
      emptyUrl: require('@/assets/imgs/emtpy.svg'),
      goodsList: [],
      title: '',
      form: {
        sort: '',
        category: ''
      },
      sortType: 'desc',
      tabIndex: 1,
      page: {
        current: 1,
        size: 10
      },
      isInit: false,
      loading: false,
      finished: false,
      totalPage: 1,
      showPopup: false
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    },
    lang() {
      return this.$store.state.lang
    },
    categoryList() {
      return this.$store.state.categoryList
    }
  },
  mounted() {
    this.title = this.$route.query.title
    this.form.category = this.$route.query.id
    this.initGoods()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeTab(i) {
      this.activeIndex = i
    },
    onLoad() {
      this.page.current += 1
      this.initGoods(true)
    },
    initGoods(isAdd) {
      this.loading = true
      shopProductList({
        current: this.page.current,
        pageSize: this.page.size,
        CategoryID: this.form.category ? parseFloat(this.form.category) : null,
        Sort: this.form.sort
      })
        .then((res) => {
          if (isAdd) {
            this.goodsList = this.goodsList.concat(res.data.Items)
          } else {
            this.goodsList = res.data.Items
          }
          this.totalPage = res.data.Pagination
            ? res.data.Pagination.totalPage
            : 1
          if (this.totalPage <= this.page.current) {
            this.finished = true
          }
          this.isInit = true
          this.loading = false
        })
        .catch((err) => {
          this.isInit = true
          this.loading = false
        })
    },
    changeOption(i) {
      if (i == this.tabIndex) {
        this.sortType = this.sortType == 'asc' ? 'desc' : 'asc'
      } else {
        this.sortType = 'desc'
        this.tabIndex = i
      }
      this.form.sort =
        i == 1
          ? ''
          : i == 2
          ? 'sales'
          : i === 3
          ? this.sortType == 'desc'
            ? 'MaxPrice'
            : 'MinPrice'
          : ''
      this.page.current = 1
      this.initGoods()
    },
    chooseCategory(id) {
      this.form.category = id
      this.showPopup = false
      this.page.current = 1
      this.initGoods()
    },
    cancel() {
      this.showPopup = false
    },
    changeCategory() {
      this.showPopup = true
    }
  }
}
</script>
<style lang="less">
</style>
